
import { Component, Vue } from 'vue-property-decorator'
import SongSearchBar from './SongSearchBar.vue'

@Component({ 
  components:{ 
    SongSearchBar
  }
})
export default class SongTemplate extends Vue {

}
