import { SearchSongFilter } from '@/models/SearchSongFilter';
import { SongResult } from '@/models/SongResult';
import { ApplyFilters } from '@/models/ApplyFilters';
import { SearchSongData } from '@/models/SearchSongData';
import router from '@/router';
import { Component, Vue } from 'vue-property-decorator'
import { BusyService } from './BusyService';
import { ApiService } from './ApiService';
import { TerritoryService } from './TerritoryService';

@Component
class SearchSongServiceClass extends Vue {
  numResults = 0;
  availableFilters:{[key: string]: {
    category: string,
    filters: SearchSongFilter[]
  }} = {
    catalogNames: {
      category: 'Catalogs',
      filters:[]
    },
    adminIds: {
      category: 'Administrators',
      filters: []
    },
    contributorNames: {
      category: 'Contributors',
      filters: []
    }
  };

  currentFilters: SearchSongFilter[] = []

  searchResults: SongResult[] | null = null;

  query : {
    page: number;
    numPerPage: number;
    sortBy: string;
    searchText: string;
    filters: ApplyFilters;
  } = {
    page: 1,
    sortBy: '',
    searchText: '',
    numPerPage: 10,
    filters: {
      catalogIds: [] as string[],
      catalogNames: [] as string[],
      adminIds: [] as string[],
      contributorNames: [] as string[]
    }
  }

  setQueryParams(){
    const data: {[key: string]: string[] | string} = {}
    if (this.query.searchText){
      data.s = this.query.searchText;
    }

    if (this.query.page > 1){
      data.page = this.query.page.toString();
    }

    if (this.query.numPerPage != 10){
      data.numperpage = this.query.numPerPage.toString();
    }

    if (this.query.sortBy){
      data.sortBy = this.query.sortBy
    }

    if (this.query.filters.catalogNames.length){
      data.catalogNames = this.query.filters.catalogNames;
    }

    if (this.query.filters.adminIds.length){
      data.adminIds = this.query.filters.adminIds;
    }

    if (this.query.filters.contributorNames.length){
      data.contributorNames = this.query.filters.contributorNames;
    }

    router.push({ path: '/intellectualproperty/songs', query: data}).catch((err)=>{});
  }

  getQueryParams(){
    if (router.currentRoute.query.s){
      this.query.searchText = router.currentRoute.query.s as string;
    }

    if (router.currentRoute.query.page) {
      this.query.page = parseInt(router.currentRoute.query.page as string)
    }else{
      this.query.page = 1;
    }

    if (router.currentRoute.query.numperpage) {
      this.query.numPerPage = parseInt(router.currentRoute.query.numperpage as string)
    }else{
      this.query.numPerPage = 10;
    }

    if (router.currentRoute.query.sortBy && ['TitleAsc', 'TitleDesc', 'LyricsAsc', 'LyricsDesc', 'ChordsAsc', 'ChordsDesc', 'SongIdAsc', 'SongIdDesc'].includes(router.currentRoute.query.sortBy as string)) {
      this.query.sortBy = router.currentRoute.query.sortBy as string;
    }else{
      this.query.sortBy = '';
    }

    this.query.filters.catalogNames = this.getArrayQuery('catalogNames');
    this.query.filters.adminIds = this.getArrayQuery('adminIds');
    this.query.filters.contributorNames = this.getArrayQuery('contributorNames');
  }

  setSortBy(sortBy: string) {
    this.query.sortBy = sortBy;
    this.query.page = 1;
    this.setQueryParams();
  }

  onSetTerritory(){
    this.query.page = 1;
    this.setQueryParams();
    this.performSearch();
  }

  getArrayQuery(param: string): string [] {
    if (!router.currentRoute.query[param]){
      return [];
    }else if(Array.isArray(router.currentRoute.query[param])){
      return router.currentRoute.query[param] as string[];
    }else{
      return [router.currentRoute.query[param]] as string[];
    }
  }

  updateSearchText(text: string){
    this.query.searchText = text;
    this.query.page = 1;
    this.query.sortBy = '';
    this.query.filters.adminIds = [];
    this.query.filters.catalogNames = [];
    this.query.filters.contributorNames = [];
    this.setQueryParams();
  }

  setNumPerPage(numPerPage: number){
    this.query.numPerPage = numPerPage;
    this.query.page = 1;
    this.setQueryParams();
  }

  setPage(page: number) {
    this.query.page = page;
    this.setQueryParams();
  }

  async performSearch() {
    BusyService.showBusy();
    const search = router.currentRoute.query.s as string || '';
    if (!search){
      this.searchResults = null;
      this.currentFilters = [];
      this.query.filters.adminIds = [];
      this.query.filters.catalogNames = [];
      this.query.filters.contributorNames = [];
      BusyService.hideBusy();
      return;
    }


    // sample api call when endpoint is ready
    const response = await ApiService.post('/api/SearchSongs', {
      territoryId: parseInt(TerritoryService.territoryId),
      filters: JSON.stringify(this.query.filters),
      search: this.query.searchText,
      page: this.query.page || 1,
      pageSize: this.query.numPerPage || 10,
      sortBy: this.query.sortBy || '', // options: TitleAsc, TitleDesc, LyricsAsc, ChordsAsc,  LyricsDesc, SongIdAsc, SongIdDesc
    }) as SearchSongData;

    if (response.availableFilters && response.availableFilters.length){
      this.availableFilters.catalogNames.filters =
        response.availableFilters.filter( (f:SearchSongFilter) => f.category === 'Catalogs');

      this.availableFilters.adminIds.filters =
        response.availableFilters.filter((f:SearchSongFilter) => f.category === 'Administrators');

      this.availableFilters.contributorNames.filters =
        response.availableFilters.filter((f:SearchSongFilter) => f.category === 'Contributors');
    }else{
      this.availableFilters.catalogNames.filters = [];
      this.availableFilters.adminIds.filters= [];
      this.availableFilters.contributorNames.filters = [];
    }

    this.currentFilters = response.currentFilters as SearchSongFilter[];
    this.searchResults = response.searchResults as SongResult[];
    this.numResults = response.numResults as number;
    BusyService.hideBusy();
  }

  lyricsCached: {[songId: string]: string[]} = {};
  async getLyrics(songId: string){
    if (!this.lyricsCached[songId]){
      const lyrics = await ApiService.post<string[]>('/api/GetSongLyricsPreview', { songId});
      this.lyricsCached[songId] = lyrics;
    }
    return this.lyricsCached[songId];

  }
}

export const SearchSongService = new SearchSongServiceClass()
